import { Input, Button, Card, TreeSelect } from "antd";
import { DownloadOutlined, FileSearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import apiCaller from "utils/apiCaller";
import OurTable from "./OurTable";
import '../../index.css';
import { Data } from "react-csv/components/CommonPropTypes";
const { Search } = Input;

interface Props {
	params: {
		target: string;
		workflowFile: string;
		colsByStep: Record<string, string[]>;
		showCols: string[];
		searchCols: string[];
		googleSheetUrl: string;
	};
}

interface Column {
	title: string;
	dataIndex: string;
	key: string;
}

const Aggregate = ({ params }: Props) => {
	const {
		target,
		workflowFile,
		colsByStep,
		showCols,
		searchCols,
		googleSheetUrl,
	} = params;
	const [loading, setLoading] = useState<boolean>(false);
	const [columns, setColumns] = useState<Column[]>();
	const [data, setData] = useState<any[]>([]);

	const [searchText, setSearchText] = useState<string>("");
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [showColumns, setShowColumns] = useState(showCols);
	const [showColFilters, setShowColFilters] = useState<string[]>(showCols);
	const allColumns = Object.values(colsByStep).flat();

	const colNameToDataIndex = (colName: string): string => {
		return colName.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
	};

	const dataIndexes = searchCols?.filter((col) => showColumns.includes(col)).map((col) => colNameToDataIndex(col));

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchedData = data.filter((column: any) =>
			Object.keys(column).some((v: any) => {
				if (dataIndexes && dataIndexes.includes(v)) {
					switch (typeof column[v]) {
						case "string":
							return `${column[v]}`.includes(e.target.value);
						case "number":
							return `${column[v]}`.includes(e.target.value);
					}
				}

				return false;
			})
		);
		setSearchText(e.target.value);
		setFilteredData(searchedData);
	};

	const createTreeSelectOptions = (columns: string[]) => {
		const options = columns.map((col) => ({
			title: `${col}`,
			value: `${col}`,
		}));

		options.unshift({
			title: "Select All",
			value: "Select All",
		});

		return options;
	};

	const onChange = (selectedValues: string[]) => {
		const isSelectAllSelected = selectedValues.includes("Select All");
		if (isSelectAllSelected) {
			setShowColFilters(["Select All", ...allColumns]);
			setShowColumns(allColumns);
		} else {
			if (showColFilters.includes("Select All")) {
				setShowColFilters([]);
				setShowColumns([]);
			} else {
				setShowColFilters(selectedValues);
				setShowColumns(selectedValues.filter((value) => value !== "Select All"));
			}
		}
	};

	const openGoogleSheet = () => {
		window.open(googleSheetUrl, "_blank");
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const stepFields = Object.fromEntries(
				Object.entries(colsByStep).map(([step, words]) => {
					const processedWords = words.map(colNameToDataIndex);
					return [step, processedWords];
				}));
			const result = await apiCaller.get("/api/aggregate", { params: { target, workflowFile, stepFields } });
			// console.log(result);
			const data = result.data;
			if (data) {
				const dataWithKeys = data.map((item: any, index: Number) => ({
					...item,
					key: `row-${index}-${new Date().getTime()}`,
				}));

				setData(dataWithKeys);
				setFilteredData(dataWithKeys);
			}
			setLoading(false);
		}

		fetchData();
	}, []);

	useEffect(() => {
		const tableCols = showColumns.map((col: string) => {
			const convertedCol = colNameToDataIndex(col);
			const defaultColumn = {
				title: col,
				dataIndex: convertedCol,
				key: convertedCol,
			};

			if (col === "Created At")
				return {
					...defaultColumn,
					render: (text: any) => {
						return new Date(text._seconds * 1000).toLocaleDateString();
					},
				};

			return {
				...defaultColumn,
			};
		});
		setColumns(tableCols);
	}, [showColumns]);

	return (
		<>
			<div
				style={{
					textAlign: "center",
				}}
			>
				<div className="search-section">
					<div className="sub-search-section">
						{searchCols && (
							<Search
								placeholder={`Search By ${searchCols.join(" / ")}`}
								onChange={handleSearch}
								allowClear={true}
								value={searchText}
							/>
						)}
					</div>

					<div className="sub-search-section">
						{showCols && (
							<TreeSelect
								treeData={createTreeSelectOptions(allColumns)}
								value={showColFilters}
								onChange={onChange}
								treeCheckable={true}
								placeholder="Select Columns to Display"
								style={{ width: "100%" }}
								defaultValue={showColumns}
								maxTagCount={2}
								maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
							/>
						)}
					</div>
				</div>
				<Card bordered={false} bodyStyle={{ padding: "0" }}>
					<div id="container" style={{ display: "flex", justifyContent: "space-between", margin: "0 0px" }}>
						<Button
							type="primary"
							style={{ alignItems: 'center' }}
							shape="round"
							icon={<FileSearchOutlined />}
							onClick={openGoogleSheet}
						>
							View in Google Sheets
						</Button>

						<Button
							type="primary"
							style={{ alignItems: "center" }}
							shape="round"
							icon={<DownloadOutlined />}
						>
							<CSVLink style={{ color: "white", width: "100px" }} data={[allColumns] as Data} filename="template.csv">
								Download CSV Template
							</CSVLink>
						</Button>

						<Button
							type="primary"
							style={{ alignItems: "center" }}
							shape="round"
							icon={<DownloadOutlined />}
						>
							<CSVLink
								style={{ color: "white", width: "100px" }}
								data={filteredData.map((row: any) => {
									const { key, ...rest } = row;
									return {
										...rest,
										createdAt: row.createdAt ? new Date(row.createdAt._seconds * 1000).toLocaleDateString() : "",
									};
								})}
								filename={`${target}.csv`}
								headers={columns?.map((col: any) => ({
									label: col.title,
									key: `${col.dataIndex}`,
								}))}
							>
								Download Current User List As CSV
							</CSVLink>
						</Button>
					</div>

					<div style={{ marginTop: "10px", marginLeft: "0px" }}>
						<OurTable columns={columns} data={filteredData} />
					</div>
				</Card >
			</div >
		</>
	);
}

export default Aggregate;

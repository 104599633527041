import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, DatePicker, Input, Modal, Space, Table } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import OurTable from "./OurTable";
import { AuthContext } from "context/AuthProvider";
import apiCaller from "utils/apiCaller";
import "../../index.css";
const { Search } = Input;

interface Props {
  data: any;
  reRunStep: boolean;
}

const getNameAndEmail = (username: string, email: string) => {
  var divStyle = { fontSize: "10px" };
  var margin = { margin: "0px" };
  let shortenedEmail = "";
  if (username !== null && username !== undefined) {
    shortenedEmail = "(" + email.split("@cs.")[0] + "@cs)";
  }
  return (
    <div>
      {" "}
      <p style={margin}>{username}</p>
      <p style={divStyle}>{shortenedEmail}</p>
    </div>
  );
};

const getWFNameAndDateAsDiv = (text: string, record: any) => {
  var divStyle = { fontSize: "10px" };
  var margin = { margin: "0px" };

  const date = new Date(record.createdAt._seconds * 1000);
  const dateTime =
    "(" +
    `${date.toLocaleDateString()}` +
    " " +
    `${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}` +
    ")";
  return (
    <div>
      {" "}
      <p style={margin}>{text}</p>
      <p style={divStyle}>{dateTime}</p>
    </div>
  );
};

const WorkflowTable = ({ data, reRunStep }: Props) => {
  const { activeUser } = useContext(AuthContext);
  const history = useHistory();

  const [workflowId, setWorkflowId] = useState("");
  const [showArchive, setShowArchive] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const filterAllDataForSortingText = (data: any) => (formatter: any) =>
    data.map((item: any) => ({
      text: formatter(item),
      value: formatter(item),
    }));

  const getStepText = (currentStep: string, workflow: any) => {
    for (const step of workflow.workflowSteps) {
      if (step["step"] === currentStep) return step["stepNls"].split('|')[0];
    }

    //Not all steps are in the step array, so looks at workflow.steps for the step title
    if(workflow.steps && currentStep in workflow.steps && "title" in workflow.steps[currentStep]){
      return workflow.steps[currentStep]['title']
    }
    else{
      return "";
    }
  };

  // const mobColumns = [
  //   {
  //     title: "Workflow Name and Creation Date",
  //     dataIndex: "workflowFile",
  //     key: "workflowFile",
  //   },
  //   {
  //     title: "Created By",
  //     dataIndex: "createdByEmailName",
  //     key: "createdByEmailName",
  //   },
  //   {
  //     title: "Assignee",
  //     dataIndex: "assigneeEmailName",
  //     key: "assigneeEmailName",
  //   },
  //   {
  //     title: "Current Step",
  //     dataIndex: "currentStep",
  //     key: "currentStep",
  //   },
  // ];

  const columns = [
    {
      title: "Workflow Name and Creation Date",
      dataIndex: "workflowFile",
      key: "workflowFile",
      filters: _.uniqWith(
        filterAllDataForSortingText(data)((i: any) => i.workflowFile),
        _.isEqual
      ),
      filterSearch: true,
      onFilter: (text: any, record: any) => record.workflowFile === text,
      sorter: (a: any, b: any) =>
        a.lastModified & b.lastModified
          ? b.lastModified?._seconds - a.lastModified?._seconds
          : b.createdAt._seconds - a.createdAt._seconds,
      // sortDirections: ['descend'],

      render: (text: any, record: any) => {
        if (text !== null && text !== undefined) {
          const details = getWFNameAndDateAsDiv(text, record);
          return details;
        } else {
          return text;
        }
      },
    },
    {
      title: "Created By",
      dataIndex: "createdByEmailName",
      key: "createdByEmailName",
      filters: _.uniqWith(
        filterAllDataForSortingText(data)((i: any) => i.createdByEmailName[1]),
        _.isEqual
      ),
      filterSearch: true,
      onFilter: (text: any, record: any) => record.createdByEmailName[1] === text,
      render: (text: any, record: any) => {
        if (text !== null && text[0] !== undefined && text[1] !== undefined) {
          const details = getNameAndEmail(text[1], text[0]);
          return details;
        } else {
          return text;
        }
      },
    },
    {
      title: "Assignee",
      dataIndex: "assigneeEmailName",
      key: "assigneeEmailName",
      filters: _.uniqWith(
        filterAllDataForSortingText(data)((i: any) => i.assigneeEmailName[1]),
        _.isEqual
      ),
      filterSearch: true,
      onFilter: (text: any, record: any) => record.assigneeEmailName[1] === text,
      render: (text: any[], record: any) => {
        if (text !== null && text[0] !== undefined && text[1] !== undefined) {
          const details = getNameAndEmail(text[1], text[0]);
          return details;
        } else {
          return text;
        }
      },
    },
    {
      title: "Current Step",
      dataIndex: "currentStep",
      key: "currentStep",
      sorter: (a: any, b: any) => getStepText(a.currentStep, a)?.localeCompare(getStepText(b.currentStep, b)),
      render: getStepText,
    },
    {
      title: "Actions",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.assignedTo === activeUser?.email ? (
            <Button
              type="primary"
              size="large"
              style={{
                width: "180px",
              }}
              onClick={() => {
                handleAccept(record.id);
              }}
            >
              Continue Workflow
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                size="large"
                style={{ width: "180px" }}
                onClick={() => history.push(`/app/workflows/${record.id}`)}
              >
                View Workflow
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];
  const actionButton = [
    {
      title: "Actions",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.assignedTo === activeUser?.email ? (
            <Button
              type="primary"
              size="large"
              style={{
                width: "180px",
              }}
              onClick={() => {
                handleAccept(record.id);
              }}
            >
              Continue Workflow
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                size="large"
                style={{ width: "180px" }}
                onClick={() => history.push(`/app/workflows/${record.id}`)}
              >
                View Workflow
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredData = data.filter((column: any) =>
      Object.keys(column).some((v: any) => {
        if (typeof column[v] === "string" && v === "workflowFile") {
          return `${column[v]}`.toLowerCase().includes(e.target.value.toLowerCase());
        } else if (typeof column[v] === "object" && v === "createdByEmailName") {
          return `${column[v][1]}`.toLowerCase().includes(e.target.value.toLowerCase());
        } else if (typeof column[v] === "object" && v === "assigneeEmailName") {
          return `${column[v][1]}`.toLowerCase().includes(e.target.value.toLowerCase());
        }
        return false;
      })
    );
    setFilteredData(filteredData);
  };

  const handleDatePick = (dates: any, dateString: [string, string]) => {
    if (dates) {
      const [startDate, endDate] = dates;
      startDate.startOf("day");
      endDate.endOf("day");
      const filteredData = data.filter((record: any) => {
        const localTime = new Date(record.createdAt._seconds * 1000);
        // Convert time to GMT (DatePicker uses GMT)
        localTime.setTime(localTime.getTime() + localTime.getTimezoneOffset() * 60 * 1000);
        const date = moment(localTime);
        if (date >= startDate && date <= endDate) {
          return true;
        }
        return false;
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(data);
    }
  };

  const handleAccept = async (workflowId: string) => {
    setConfirmLoading(true);
    let requestData: any = { workflowId, currentAssignee: activeUser?.email };
    if (reRunStep) {
      requestData.reRunStep = reRunStep;
    }
    await apiCaller.post("/api/accept-workflow", requestData, {});
    setConfirmLoading(false);
    history.push(`/app/workflows/${workflowId}`);
  };

  const handleArchive = async () => {
    setConfirmLoading(true);
    await apiCaller.post("/api/archive-workflow", {
      workflowId,
      type: "VOID",
    });
    setConfirmLoading(false);
    setWorkflowId("");
    setShowArchive(false);
  };

  const emptyText = "No workflows";

  return (
    <>
      <div className="search-section">
        <div className="sub-search-section">
          <Search
            placeholder="Search By WorkFlow Name / User Name"
            allowClear
            enterButton="Search"
            onChange={handleSearch}
          />
        </div>
        <div className="sub-search-section">
          <DatePicker.RangePicker onChange={handleDatePick} />
        </div>
      </div>
      {/* <Table
        columns={columns}
        dataSource={filteredData}
        locale={{
          emptyText,
        }}
        scroll={{ x: 1300 }}
      /> */}
      <OurTable
        data={filteredData} 
        columns={columns}
        actionButton={actionButton}
        // activeUser={activeUser}
      ></OurTable>
      <Modal
        title="Archive Workflow?"
        visible={showArchive}
        onOk={handleArchive}
        confirmLoading={confirmLoading}
        okText={"Archive"}
        cancelText={"Cancel"}
        onCancel={() => setShowArchive(false)}
      >
        Are you sure you want to archive this workflow?
      </Modal>
    </>
  );
};

export default WorkflowTable;

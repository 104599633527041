import { Checkbox, Col, Input, Row, Table, Tag } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import apiCaller from 'utils/apiCaller';
import { AuthContext } from "context/AuthProvider";
import OurTable from './OurTable';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface Props {
  type: string;
  onSelect: (user: any) => void;
}

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'SBU ID',
    dataIndex: 'sbuId',
    key: 'sbuId',
  },
  {
    title: 'Advisor',
    dataIndex: 'advisor',
    key: 'advisor',
    render: (advisor?: { current?: string; previous?: string }) => {
      if (!advisor || (!advisor.current && !advisor.previous)) {
        return <span>No Advisor</span>;
      }
      return (
        <>
          {advisor.current && <Tag color="green">{`Current: ${advisor.current}`}</Tag>}
          {advisor.previous && <Tag color="blue">{`Previous: ${advisor.previous}`}</Tag>}
        </>
      );
    },
  }
  
];

interface DataType {
  key: React.Key;
  firstName: string;
  lastName: string;
  sbuId: string;
  role: string;
  advisor:any;
}

function AdvisorTable({ type, onSelect }: Props) {
    const [data, setData] = useState<DataType[]>([]); // Original dataset
    const [filteredData, setFilteredData] = useState<DataType[]>([]); // Filtered data to display
    const [showAdvisorsOnly, setShowAdvisorsOnly] = useState(false); // Filter toggle
    const [searchQuery, setSearchQuery] = useState(''); // Search query state
  
    useEffect(() => {
      async function fetchUsers() {
        const res = await apiCaller.get('/api/users');
        const userData = res.data.map((d: any, i: number) => ({ ...d, key: i }));
        setData(userData);
        setFilteredData(userData); // Initialize filteredData with the full dataset
      }
      fetchUsers();
    }, []);
  
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const query = e.target.value.toLowerCase();
      setSearchQuery(query); // Save the search query
      applyFilters(query, showAdvisorsOnly); // Apply filters with updated query
    };
  
    const handleAdvisorFilterChange = (e: CheckboxChangeEvent) => {
      const isChecked = e.target.checked;
      setShowAdvisorsOnly(isChecked); // Save the checkbox state
      applyFilters(searchQuery, isChecked); // Apply filters with updated checkbox state
    };
  
    const applyFilters = (query: string = '', advisorsOnly: boolean = false) => {
      const baseData = [...data]; // Start filtering from the full dataset
  
      const result = baseData.filter((item) => {
        // Apply advisor filter
        const hasAdvisor = item.advisor && (item.advisor.current || item.advisor.previous);
        if (advisorsOnly && !hasAdvisor) {
          return false;
        }
  
        // Apply search filter
        if (query) {
            const matchesSearch = (Object.keys(item) as (keyof DataType)[]).some((key) => {
                const value = item[key]; // TypeScript now knows `key` is valid
                return typeof value === 'string' && value.toLowerCase().includes(query);
              });
          if (!matchesSearch) {
            return false;
          }
        }
  
        return true;
      });
  
      setFilteredData(result); // Update filteredData with the results
    };
  
    return (
      <>
        <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={18}>
                <Input placeholder='Search' onChange={handleSearch} />
            </Col>
            <Col span={6}>
                <Checkbox onChange={handleAdvisorFilterChange} checked={showAdvisorsOnly}>
                Show Students with Advisors Only
                </Checkbox>
            </Col>
        </Row>

        <OurTable data={filteredData} columns={columns}></OurTable>
      </>
    );
  }
  
  export default AdvisorTable;
  
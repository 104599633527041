import { Input, Table, Tag } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import apiCaller from 'utils/apiCaller';
import { AuthContext } from "context/AuthProvider";
import OurTable from './OurTable';

interface Props {
  type: string;
  onSelect: (user: any) => void;
}

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'SBU ID',
    dataIndex: 'sbuId',
    key: 'sbuId',
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
    render: (roles: string[]) => (
      <>{roles && roles.map((role: string) => <Tag key={role}>{role}</Tag>)}</>
    ),
  },
  {
    title: 'Impersonation Roles',
    dataIndex: 'impRoles',
    key: 'impRoles',
    render: (impRoles: string[]) => (
      <>
        {impRoles &&
          impRoles.map((impRole: string) => <Tag key={impRole}>{impRole}</Tag>)}
      </>
    ),
  },
];

interface DataType {
  key: React.Key;
  firstName: string;
  lastName: string;
  sbuId: string;
  role: string;
}

function ResourceTable({ type, onSelect }: Props) {
  const [data, setData] = useState<DataType[]>([]);
  const { activeUser } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);

  useEffect(() => {
    async function fetchUsers() {
      const res = await apiCaller.get('/api/users');
      const userData = res.data.map((d: any, i: number) => ({ ...d, key: i }));
      setData(userData);
      setFilteredData(userData);
    }
    fetchUsers();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      onSelect(selectedRows[0]);
    },
    getCheckboxProps: (record: DataType) => {
      return {
        disabled:record.firstName+" "+record.lastName=== 'Disabled User',
        name: record.firstName+" "+record.lastName,
      };
    },
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredData = data.filter((column: any) =>
      Object.keys(column).some((v: any) => {
        if (typeof column[v] === 'string') {
          return `${column[v]}`
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      })
    );
    setFilteredData(filteredData);
  };

  return (
    <>
      <Input placeholder='Search' onChange={handleSearch} />
      {/* <Table
        rowSelection={{ type: 'radio', ...rowSelection }}
        columns={columns}
        dataSource={filteredData}
      /> */}
      <OurTable
        rowSelection={{ type: 'radio', ...rowSelection }}
        data={filteredData}
        columns={columns}
        // activeUser={activeUser}
      ></OurTable>
    </>
  );
}

export default ResourceTable;

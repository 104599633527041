import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Input, TreeSelect, message } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import CSVReader, { IFileInfo } from "react-csv-reader";
import { Data } from "react-csv/components/CommonPropTypes";
import apiCaller from "utils/apiCaller";
import OurTable from "./OurTable";

const { Search } = Input;

const papaparseOptions = {
	header: true,
	skipEmptyLines: true,
	dynamicTyping: true,
};

interface Column {
	title: string;
	dataIndex: string;
	key: string;
}

interface Props {
	params: {
		columns: string[];
		type: string;
		target: string;
		showExisting: boolean;
		workflowFile?: string;
		formStep?: string;
		searchCols?: string[];
		selectShowCols?: string[];
	};
	prevButton: React.ReactNode;
	nextStepTitle: string;
	onComplete: (data: any) => void;
}

function WorkflowCSV({ params, nextStepTitle, prevButton, onComplete }: Props) {
	const durationInSeconds = 2;
	const {
		columns: csvColumns,
		type,
		target,
		showExisting,
		workflowFile,
		formStep,
		searchCols,
		selectShowCols,
	} = params;
	const [loading, setLoading] = useState(true);
	const [columns, setColumns] = useState<Column[]>();
	const [data, setData] = useState<any[]>([]);
	const [readerKey, setReaderKey] = useState(Date.now());

	const [searchText, setSearchText] = useState<string>("");
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [showColumns, setShowColumns] = useState(csvColumns);
	const [showColFilters, setShowColFilters] = useState<string[]>(["Select All", ...csvColumns]);

	const colNameToDataIndex = (colName: string): string => {
		return colName.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
	};

	const dataIndexes = searchCols?.filter((col) => showColumns.includes(col)).map((col) => colNameToDataIndex(col));

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchedData = data.filter((column: any) =>
			Object.keys(column).some((v: any) => {
				// let dataIndexes = ["name", "sbuId"];
				if (dataIndexes && dataIndexes.includes(v)) {
					switch (typeof column[v]) {
						case "string":
							return `${column[v]}`.includes(e.target.value);
						case "number":
							return `${column[v]}`.includes(e.target.value);
					}
				}

				return false;
			})
		);
		setSearchText(e.target.value);
		setFilteredData(searchedData);
	};

	useEffect(() => {
		if (!csvColumns) {
			message.warning("Invalid workflow file - no CSV columns found", durationInSeconds);
		}
		async function fetchData() {
			const res = await apiCaller.get(`/api/csv`, {
				params: { target, workflowFile, formStep },
			});
			let resData = res.data;

			if (resData && resData.length > 0) {
				resData = resData.map((row: any) => {
					if (row.roles && row.impRoles) {
						return {
							...row,
							roles: row.roles.join("|"),
							impRoles: row.impRoles.join("|"),
						};
					}
					return { ...row };
				});
				console.log(res);
				setData(resData);
				setFilteredData(resData);
			} else {
				setData([]);
			}
		}

		if (type === "download" || showExisting) {
			fetchData();
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		const tableCols = showColumns.map((col: string) => {
			const convertedCol = colNameToDataIndex(col);
			const defaultColumn = {
				title: col,
				dataIndex: convertedCol,
				key: convertedCol,
			};

			if (col === "Created At")
				return {
					...defaultColumn,
					render: (text: any) => {
						return new Date(text._seconds * 1000).toLocaleDateString();
					},
				};

			return {
				...defaultColumn,
			};
		});
		setColumns(tableCols);
	}, [showColumns]);

	const createTreeSelectOptions = (columns: string[]) => {
		const options = columns.map((col) => ({
			title: `${col}`,
			value: `${col}`,
		}));

		options.unshift({
			title: "Select All",
			value: "Select All",
		});

		return options;
	};

	const onChange = (selectedValues: string[]) => {
		const isSelectAllSelected = selectedValues.includes("Select All");
		if (isSelectAllSelected) {
			setShowColFilters(["Select All", ...csvColumns]);
			setShowColumns(csvColumns);
		} else {
			if (showColFilters.includes("Select All")) {
				setShowColFilters([]);
				setShowColumns([]);
			} else {
				setShowColFilters(selectedValues);
				setShowColumns(selectedValues.filter((value) => value !== "Select All"));
			}
		}
	};

	const adminEmails = [
		// Predefined admin user roles and impRoles
		{
			email: "mantsingh@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin",
			impRoles: "admin|superadmin|grad|undergrad",
		},
		{
			email: "samaggio@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin",
			impRoles: "admin|superadmin|grad|undergrad",
		},
		{
			email: "btiev@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin",
			impRoles: "admin|superadmin|grad|undergrad",
		},
		{
			email: "jiachzhu@cs.stonybrook.edu",
			roles: "undergrad|admin|superadmin",
			impRoles: "admin|superadmin|grad|undergrad",
		},
		{
			email: "anphilip@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin",
			impRoles: "admin|superadmin|grad|undergrad",
		},
		{
			email: "mkhamkar@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin",
			impRoles: "admin|superadmin|grad|undergrad",
		},
		{
			email: "mferdman@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin|faculty",
			impRoles: "admin|superadmin|faculty|grad|undergrad",
		},
		{
			email: "sagaddam@cs.stonybrook.edu",
			roles: "grad|g2|admin|superadmin",
			impRoles: "admin|superadmin",
		},
		{
			email: "samaggio@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin",
			impRoles: "admin|superadmin",
		},
		{
			email: "btiev@cs.stonybrook.edu",
			roles: "grad|g1|admin|superadmin",
			impRoles: "admin|superadmin",
		},
	];

	const filterCSVData = (data: any[]) => {
		return data.filter((obj) => !Object.values(obj).every((val) => val === null));
	};

	const handleUpload = (data: any[], fileInfo: IFileInfo) => {
		if (data.length > 0) {
			let filtered = filterCSVData(data);

			// Iterate over each record in the uploaded data
			const dataSource = filtered.map((d: any) => {
				// Transform the keys into the format used by the state (camelCase)
				let transformedData = _.mapKeys(d, (value, key) => _.camelCase(key));

				// Check if the email of the current record matches any in the adminEmails array
				const adminUser = adminEmails.find(
					(admin) => admin.email.toLowerCase() === transformedData.email.toLowerCase()
				);
				if (adminUser) {
					// If the record is an admin, preserve their existing roles and impRoles
					transformedData.roles = adminUser.roles;
					transformedData.impRoles = adminUser.impRoles;
					// If the record is not an admin, process it as per your application's needs
					// (e.g., updating roles or impRoles based on the CSV data)
				} else {
					// Check if the user is an undergraduate or graduate and update their role accordingly
					const roleTypes = ["undergrad|", "grad|"]; // List of role types to check
					roleTypes.forEach((roleType) => {
						if (transformedData.roles.startsWith(roleType)) {
							const currentRoleParts = transformedData.roles.split("|");
							const newRoleParts = d.Roles.split("|"); // Assuming 'Roles' is the column name from the CSV
							if (newRoleParts[0] === roleType.slice(0, -1) && newRoleParts.length > 1) {
								// Replace the level with the new one from the CSV
								currentRoleParts[1] = newRoleParts[1];
								transformedData.roles = currentRoleParts.join("|");
							}
						}
					});
				}
				return transformedData;
			});

			const dataWithKeys = dataSource.map((item, index) => ({
				...item,
				key: `row-${index}-${new Date().getTime()}`, // Unique key using index + timestamp
			}));

			// Update the state with the new, processed data
			setData(dataWithKeys);
			setFilteredData(dataWithKeys);
			setReaderKey(Date.now());
		} else {
			message.error("Invalid CSV file. Please download CSV template");
		}
	};

	if (loading || !csvColumns) {
		return null;
	}
	return (
		<>
			<div
				style={{
					textAlign: "center",
				}}
			>
				<div className="search-section">
					{searchCols && (
						<div className="sub-search-section">
							<Search
								placeholder="Search By Name / SBU ID"
								onChange={handleSearch}
								allowClear={true}
								value={searchText}
							/>
						</div>
					)}
					<div className="sub-search-section">
						{selectShowCols && (
							<TreeSelect
								treeData={createTreeSelectOptions(csvColumns)}
								value={showColFilters}
								onChange={onChange}
								treeCheckable={true}
								placeholder="Select Columns to Display"
								style={{ width: "100%" }}
								defaultValue={showColumns}
								maxTagCount={2}
								maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
							/>
						)}
					</div>
				</div>
				<Card bordered={false} bodyStyle={{ padding: "0" }}>
					<div id="container" style={{ marginLeft: "0px" }}>
						<Button
							type="primary"
							style={{ alignItems: "center" }}
							shape="round"
							icon={<DownloadOutlined />}
						>
							<CSVLink style={{ color: "white", width: "100px" }} data={[csvColumns] as Data} filename="template.csv">
								Download CSV Template
							</CSVLink>
						</Button>

						{type !== "download" ? (
							<CSVReader
								key={readerKey}
								label={`Upload CSV for ${target} below`}
								onFileLoaded={handleUpload}
								parserOptions={papaparseOptions}
							/>
						) : (
							<Button
								type="primary"
								style={{ alignItems: "center", marginLeft: "30px" }}
								shape="round"
								icon={<DownloadOutlined />}
							>
								<CSVLink
									style={{ color: "white", width: "100px" }}
									data={filteredData.map((row: any) => {
										return {
											...row,
											createdAt: row.createdAt ? new Date(row.createdAt._seconds * 1000).toLocaleDateString() : "",
										};
									})}
									filename={`${target}.csv`}
									headers={columns?.map((col: any) => ({
										label: col.title,
										key: `${col.dataIndex}`,
									}))}
								>
									Download Current User List As CSV
								</CSVLink>
							</Button>
						)}
					</div>

					<div style={{ marginTop: "10px", marginLeft: "0px" }}>
						<OurTable columns={columns} data={filteredData} />
					</div>
				</Card>
			</div>
			{prevButton}
			<Button type="primary" onClick={() => onComplete(data)}>
				{type == "update" ? "Update CSV" : nextStepTitle.split("|")[0]}
			</Button>
		</>
	);
}

export default WorkflowCSV;

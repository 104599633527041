import { Checkbox, Col, Input, Row, Select, Tag } from "antd";
import React, { useEffect, useState, useContext } from "react";
import apiCaller from "utils/apiCaller";
import { AuthContext } from "context/AuthProvider";
import OurTable from "./OurTable";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { SelectValue } from "antd/lib/select";

interface UserData {
  email: string;
  name: string;
  id: React.Key;
}
interface DataType {
  email: string;
  name: string;
  id: React.Key;
  workflowId: string;
  userId: string;
  type: string;
  timestamp: string;
  data: any;
}

function StudentHistoryTable() {
  const [users, setUsers] = useState<UserData[]>([]);
  const [data, setData] = useState<DataType[]>([]); // Original dataset
  const [filteredData, setFilteredData] = useState<DataType[]>([]); // Filtered data to display
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(null);
  const [selectedStudentName, setSelectedStudentName] = useState<string | null>(null);
  const [workflowNames, setWorkflowNames] = useState<Record<string, string>>({}); // Map of workflowId to workflowName
  
  // Fetch users for the dropdown
  useEffect(() => {
    async function getUserData() {
      try {
        const usersData = await apiCaller.get("/api/users");
        const users = usersData.data.map((user: any) => ({
          id: user.id,
          name: user.firstName + " " + user.lastName,
          email: user.email,
        }));
        setUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
    getUserData();
  }, []);

    // Fetch user history data
    useEffect(() => {
      const getUserHistory = async () => {
        try {
          const res = await apiCaller.get("/api/get-user-history", {
            params: { userId: selectedStudentId },
          });
  
          // Format the data to handle json
          const formattedData = res.data.map((item: any) => ({
            ...item,
            timestamp: new Date(item.timestamp._seconds * 1000).toLocaleString(), // Format timestamp
            data: {
              advisor_current: item.data?.advisor_current || "N/A", // Handle missing data
              advisor_previous: item.data?.advisor_previous || "N/A",
            },
          }));
  
          setData(formattedData);
          setFilteredData(formattedData);
        } catch (error) {
          console.error("Error fetching user history:", error);
        }
      };
      getUserHistory();
    }, [selectedStudentId]);

  //create workflow map for the table
  useEffect(() => {
    const fetchWorkflowNames = async () => {
      const uniqueWorkflowIds = Array.from(new Set(data.map((item) => item.workflowId)));

      try {
        const responses = await Promise.all(
          uniqueWorkflowIds.map((workflowId) =>
            apiCaller
              .get("/api/workflow", { params: { id: workflowId } })
              .then((response) => ({ workflowId, name: response.data.workflowFile }))
              .catch(() => ({ workflowId, name: workflowId }))
          )
        );

        const namesMap = responses.reduce((acc, { workflowId, name }) => {
          acc[workflowId] = name;
          return acc;
        }, {} as Record<string, string>);

        setWorkflowNames(namesMap);
      } catch (error) {
        console.error("Error fetching workflow names:", error);
      }
    };
    if (data.length > 0) {
      fetchWorkflowNames();
    }
  }, [data]);

  const columns = [
    {
      title: "Workflow Name",
      dataIndex: "workflowId",
      key: "workflowId",
      render: (workflowId: string) => <span>{workflowNames[workflowId] || "Loading..."}</span>,
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      render: () => <span>{selectedStudentName}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: { _seconds: number; _nanoseconds: number }) => {
        const date = new Date(timestamp._seconds * 1000);
        return date.toLocaleString(); // Format the timestamp into a readable date and time
      },
    },
    {
      title: "Data Change",
      dataIndex: "data",
      key: "data",
      render: (data: { advisor_current?: string; advisor_previous?: string }) => {
        if (!data || !data.advisor_current) {
          return <span>No Change</span>;
        }
        return (
          <>
            <Tag color="green">{`Current: ${data.advisor_current}`}</Tag>
            <Tag color="red">{`Previous: ${data.advisor_previous}`}</Tag>
          </>
        );
      },
    },
  ];

  // Handle search input
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = data.filter(
      (item) => (!selectedStudentId || item.userId === selectedStudentId) && item.name.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  // Handle dropdown selection
  const handleSelect = (value: string | null | undefined) => {
    if (value) {
      const { id, name } = JSON.parse(value); // Parse the JSON string
      setSelectedStudentId(id);
      setSelectedStudentName(name);
    } else {
      setSelectedStudentId(null);
      setSelectedStudentName(null);
    }
  };

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Select
            placeholder="Select a student"
            style={{ width: "100%" }}
            showSearch
            allowClear
            onChange={(value) => handleSelect(value as string | null | undefined)} // Explicitly cast `value`
            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
            options={users.map((user) => ({
              value: JSON.stringify({ id: user.id, name: user.name }), // Pass both id and name as JSON
              label: `${user.name} - ${user.email}`,
            }))}
          />
        </Col>
        <Col span={12}>
          <Input placeholder="Search" value={searchQuery} onChange={handleSearch} />
        </Col>
      </Row>

      <OurTable data={filteredData} columns={columns} />
    </>
  );
}

export default StudentHistoryTable;

import { useState } from "react";
import WorkflowGenerate from "./WorkflowGenerate";
import WorkflowFileDisplay from "./WorkflowFileDisplay";

interface filesJsonStruct {
    nameDisplayedInInput: string;
    PdfStep: string;
}

const MultiFileDisplay = ({ filesJson, currentStep, workflowId, workflow, retrieve }: any) => {
    const [selectionChoice, setSelectionChoice] = useState<string>("");

    return(
        <>
        <div style={{
            padding: "16px",
        }}>
        {/* If many files are specified by the workflow */}
        {filesJson.length>1 ?
            <>
            <label htmlFor="dynamic-select" style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "bold",
            }}>Choose a file:</label>
            <select
                id="dynamic-select"
                style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "16px",
                }}
                value={selectionChoice}
                onChange={(event) => setSelectionChoice(event.target.value)}
            >
                <option value="" disabled>
                -- Select an option --
                </option>
                {filesJson.map((item: filesJsonStruct, index: number) => (
                <option key={index} value={item.PdfStep}>
                    {item.nameDisplayedInInput}
                </option>
                ))}
            </select>
            {/* For debugging if you want to see which option was selected */}
            {/* <p>Selected Value: {selectionChoice}</p> */}
            
            {/* Try to use WorkflowFileDisplay to display other documents not explicitly specified here. 
            You might need to make a new component to display the new desired file.
            Could try adding a parameter that specifies the endpoint to retrieve the desired file. */}
            {selectionChoice == "student_file_upload" && (
                <WorkflowFileDisplay workflowId={workflowId} workflowStepWithDesiredDocument={"student_file_upload"} workflow={workflow} retrieve={retrieve}/>
            )}
            {selectionChoice == "generate_pdf" &&(
                <WorkflowGenerate workflowId={workflowId} workflow={workflow} retrieve={true} />
            )}
            </>
            :
            <>
            {/* If only one file is specified by the workflow */}
            {filesJson.length==1 &&(
                <>
                {filesJson[0]["PdfStep"] == "student_file_upload" && (
                    <WorkflowFileDisplay workflowId={workflowId} workflowStepWithDesiredDocument={"student_file_upload"} workflow={workflow} retrieve={retrieve}/>
                )}
                {filesJson[0]["PdfStep"] == "generate_pdf" &&(
                    <WorkflowGenerate workflowId={workflowId} workflow={workflow} retrieve={true} />
                )}
                </>
                
            )}
            {/* If no files are specified by the workflow */}
            {filesJson.length==0 &&(
                <h2>No files were specified for this step</h2>
            )}
            </>
        }
        </div>
        </>
    )
}
export default MultiFileDisplay